const Text = {
  baseStyle: {},
  sizes: {},
  variants: {
    "desktop-button-bold": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "24px",
    },
    "desktop-caption": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
    },
    "desktop-display-xl": {
      fontFamily: "Dimbo",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "40px",
      lineHeight: "56px",
    },
    "desktop-heading": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "30px",
    },
    "desktop-heading-6": {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "21px",
    },
    "desktop-xs": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
    },
    "desktop-body": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "27px",
    },
    "desktop-body-bold": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "27px",
    },
    "mobile-body-bold": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "21px",
    },
    "mobile-button-bold": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "28px",
    },
    "mobile-button-bold-2": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "28px",
    },
    "mobile-caption": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "24px",
    },
    "mobile-caption-2": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "18px",
    },
    "mobile-caption-bold": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "18px",
    },
    "mobile-display-xl": {
      fontFamily: "Dimbo",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "34px",
    },
    "mobile-xs": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
};

export default Text;
